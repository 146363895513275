<template>
  
  <v-container class="home">

    <h1>
      Seu agendamento foi realizado com sucesso! <br />
      Obrigado por utilizar nosso sistema de agendamento.
    </h1>
    <v-btn class="btn" to="/">
      <v-img src="../assets/IconEcon.png" alt="Logo" class="img" />
      Voltar
    </v-btn>
       
  </v-container>
</template>

<script>
export default {
  name: "Obrigado",
  mounted(){
    localStorage.removeItem("dados");
    localStorage.removeItem("data");
    localStorage.removeItem("especialidade");
    localStorage.removeItem("idProfissional");
    localStorage.removeItem("profissional");
    localStorage.removeItem("horario");
    localStorage.removeItem("idProcedimento");
  }
};
</script>
<style lang="sass" >
.home
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  height: 70vh

  h1
    margin-bottom: 20px

  .img
    width: 28px
    height: 28px
    margin: 5px
</style>